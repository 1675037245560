"use client";

import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../styles/TopSeller.module.css";
import Slider from "react-slick";
import { apple, samsung, oneplus, storeLogo } from "../../../public/images";
import Image from "next/image";
import ScrollDown from "../../components/ScrollDown";
import Link from "next/link";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTopSeller } from "../../app/apis/getApis";
import { useState } from "react";
import { marketplaceCdn } from "../../utility/env";
import { store_visit } from "../../UserEvents/UserEvents";
import { useUserStore } from "../../store/User";
import { LoadingCard } from "../../components";
import ProductCardLoading from "../SpecDetails/ProductCardLoading";

const ImageContainer = ({ name, url }: any) => {
  const [hasimg, setHasimg] = useState(url ? true : false);

  return (
    <div className={`${styles.sellerSlider} `}>
      {hasimg ? (
        <Image
          src={`${marketplaceCdn}/${url}`}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      ) : (
        <Image
          src={storeLogo}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      )}
    </div>
  );
};

const TopSellers = () => {
  const [data, setData] = useState([]);
  const { user, token } = useUserStore((state: any) => state);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
    ],
  };

  const topSellerQuery = useQuery({
    queryKey: ["topSeller"],
    queryFn: () => fetchTopSeller(),
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setData(data.data.body.data);
    },
  });


  return (
    <div className={`${styles.topSellers} `}>
      <main className=" main  ">
        <Row className={`justify-content-center text-nowrap py-3 `}>
          <Col xs="4" sm="4" md="2" lg="2" className="px-0">
            <h2 className={`${styles.header} text-center`}>Top Sellers</h2>
          </Col>
        </Row>

        <Row className="g-2 g-sm-2 g-md-3 g-lg-3 g-xl-3 justify-content-center flex-sm-wrap">
          {topSellerQuery.isLoading
            ?
            <div className="d-flex flex-wrap justify-content-center w-100  " style={{ columnGap: '10px' }}>
              {[1,2,3,].map((item) => (
                <CardLoading />
              ))}
            </div>

            : data.map((seller, index) => (
              <Col className="justify-content-center" lg={3} md={4} sm={6} xs={10} key={index}>
                <Link href={`/store/${seller.username}`} onClick={() => store_visit(seller?.shop_id, user?.uuid, 'Top Seller', `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}store/${seller.username}`, seller?.shop_name)}>
                  <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2`}>
                    <div className={`${styles.seller_logo} rounded-circle border border-1`}>
                      <Image
                        src={seller.logo_path ? `${process.env.NEXT_PUBLIC_APP_URL_S3}/${seller.logo_path}` : storeLogo}
                        width={64}
                        height={64}
                        className="h-100 p-1 rounded-circle w-100"
                        alt={`${seller.shop_name} logo`}
                      />
                    </div>
                    <div className="seller-name align-content-center">
                      <p className="fw-500 fs-14 h-100 align-middle d-inline">
                        {seller.shop_name}
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </main>
    </div>
  );
};

export default TopSellers;



const CardLoading = () => {

  return (

    <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2 ${styles.skeleton} w-25  `}>
      <div className={`${styles.seller_logo} rounded-circle border border-1 ${styles.skeleton}`} />
      <div className="seller-name align-content-center">
        <p className={`fw-500 fs-14 h-100 align-middle d-inline ${styles.skeleton} ${styles.skeleton_text}`} />
      </div>
    </div>

  )
}
