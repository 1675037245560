// import { ProductCard } from "../../components/ProductCard";
"use client";

import Image from "next/image";
import React, { useState } from "react";
import Slider from "react-slick";
import styles from "../../styles/FeaturedCategories.module.css";
import {
  chevronLeft,
  chevronRight,
  SliderLeft,
  SliderRight,
} from "../../../public/icons";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ScrollDown from "../../components/ScrollDown";
import { useRouter } from "next/navigation";
import { sendGTMEvent } from '@next/third-parties/google'
import Link from "next/link";
import {
  desktopImage,
  headPhoneImage,
  laptop,
  MobilePic,
  tabletImage,
  TV,
} from "../../../public/images";
import { motion } from "framer-motion";

const FeaturedCategories = () => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const router = useRouter();
  const settings = {
    className: "center",
    infinite: true,
    // centerMode: true,
    arrows: false,
    centerPadding: "10px",
    slidesToShow: 4,
    swipeToSlide: true,
    variableWidth: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1824,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 990,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: function (index: any) {
      `Slider Changed to: ${index + 1}, background: #222; color: #bada55`;
    },
  };
  const data = [
    {
      id: 894,
      title: "Mobiles ",
      quantity: 4,
      sale_price: 450,
      created_at: "2023-04-25T17:15:39.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `	${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/inventoryImages/Eqdohl0Gy5LVlRqH1674299678115.png`,
    },
    {
      id: 895,
      title: "Laptops ",
      quantity: 7,
      sale_price: 950,
      created_at: "2023-04-25T17:26:01.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/dummyImages/laptops.jpg`,
    },
    {
      id: 898,
      title: "Tabs",
      quantity: 65,
      sale_price: 600,
      created_at: "2023-04-26T09:20:18.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/inventoryImages/tlC40RKerSmFApbi1682482817383.jfif`,
    },
    {
      id: 900,
      title: " Tv / Monitors ",
      quantity: 7,
      sale_price: 73,
      created_at: "2023-04-26T09:31:51.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/inventoryImages/H5YHRWJ8eULo6z0m1682483510793.jfif`,
    },
    {
      id: 913,
      title: "Accessories ",
      quantity: 5,
      sale_price: 6600,
      created_at: "2023-04-27T10:31:21.000Z",
      updated_at: "2023-04-27T10:46:00.000Z",
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/inventoryImages/IFSh12Wn4RW4THLb1682573480179.jfif`,
    },
    {
      id: 2372,
      title: "Apple 56 2GB 64GB SSD 160GB HDD 12th Gen A12 Bionic 10.1 Inches ",
      quantity: 20,
      sale_price: 22,
      created_at: "2023-06-08T18:04:24.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/dummyImages/laptops.jpg`,
    },
    {
      id: 2373,
      title: "Acer test 64GB SSD 10th Gen A11 Bionic 7 Inches ",
      quantity: 2,
      sale_price: 900,
      created_at: "2023-06-09T11:47:03.000Z",
      updated_at: null,
      label: "Laptop",
      location_id: 334,
      img0: `${process.env.NEXT_PUBLIC_MARKETPLACE_CDN}/images/dummyImages/laptops.jpg`,
    },
  ];
  return (
    <div className={styles.feature_div}>
      <div className="main">
        <div className={` ${styles.container} px-sm-1`}>
          {/* <h1 className={`pb-2 ${styles.rPe_h1} `}> Categories</h1> */}
          <div className={styles.re_paragraph_div}>
            <Row className="d-flex gap-2  justify-content-center w-100 ">
            <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `Laptops`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=1` })
                  router.push("/products?params=category_id=1");
                }}
              >
                <Col
                  whileHover={{ scale: 1.1 }}
                  as={Link}
                  // onClick={() => {
                  //   sendGTMEvent({ event: 'Category-Clicked', value: `Laptops`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=1` })
                  //   router.push("/products?params=category_id=1");
                  // }}
                  href="/products?params=category_id=1"
                  className={`d-grid my-sm-1 col-auto`}
                >
                  <Image
                    alt={MobilePic}
                    height={64}
                    width={64}
                    src={laptop}

                  />
                  <span  className={`fw-normal ${styles.CategoryText} text-center`}>Laptops</span>
                </Col>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                as={Link}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `Mobile`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=2`  })
                
                  


                  router.push("/products?params=category_id=2");
                }}
                href="/products?params=category_id=2"
              >
                <Col
              
                  className={`d-grid my-sm-1   `}
                  lg={2}
                >
                  <Image
                    alt={MobilePic}
                    height={64}
                    width={64}
                    src={MobilePic}
                  />
                  <span className={`fw-normal ${styles.CategoryText} text-center`}>Mobiles</span>
                </Col>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `Tablets`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=3`  })
                  router.push("/products?params=category_id=3");
                }}
              >
                <Col
                  
                  
                  as={Link}
                  href="/products?params=category_id=3"
                  className={`d-grid my-sm-1 col-auto`}
                >
                  <Image
                    style={{ transform: "rotate(90deg)" }}
                    alt={MobilePic}
                    height={64}
                    width={64}
                    src={tabletImage}
                  />
                  <span  className={`fw-normal ${styles.CategoryText} text-center`}>Tablets</span>
                </Col>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `TV/Monitors`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=4`  })
                  router.push("/products?params=category_id=4");
                }}
              >
                <Col
                  whileHover={{ scale: 1.1 }}
                  as={Link}
                  href="/products?params=category_id=4"
                  className={`d-grid my-sm-1 col-auto`}
                >
                  <Image alt={MobilePic} height={64} width={64} src={TV} className="mx-auto"/>
                  <span  className={`fw-normal ${styles.CategoryText} text-center`}>TV Monitors</span>
                </Col>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `Desktops`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=5`  })
                  router.push("/products?params=category_id=5");
                }}
              >
                <Col
                  whileHover={{ scale: 1.1 }}
                  as={Link}

                
                  onClick={() => {
                    sendGTMEvent({ event: 'Category-Clicked', value: `Desktops`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=5`  })
                    router.push("/products?params=category_id=5");
                  }}
                  href="/products?params=category_id=5"
                  className={`d-grid my-sm-1 col-auto `}
                >
                  <Image
                    style={{ mixBlendMode: "multiply" }}
                    alt={MobilePic}
                    height={64}
                    width={64}
                    src={desktopImage}
                  />
                  <span  className={`fw-normal ${styles.CategoryText} text-center`}>Desktops</span>
                </Col>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className={`d-grid my-sm-1 justify-content-center  ${styles.gridItems}`}
                onClick={() => {
                  sendGTMEvent({ event: 'Category-Clicked', value: `Accessories`, location: 'Home',url:process.env.NEXT_PUBLIC_MARKETPLACE_URL+`products?params=category_id=6`  })
                  router.push("/products?params=category_id=6");
                }}
              >
                <Col
                  whileHover={{ scale: 1.1 }}
                  as={Link}
                  href="/products?params=category_id=6"
                  className={`d-grid my-sm-1 col-auto `}
                >
                  <Image
                    alt={MobilePic}
                    height={64}
                    width={64}
                    src={headPhoneImage}
                    className="mx-auto"
                  />
                  <span className={`fw-normal${styles.CategoryText} text-center`}>Accessories</span>
                </Col>
              </motion.div>
              
             
            </Row>
            
          </div>
        </div>

       
      </div>
    </div>
  );
};

export default FeaturedCategories;

const RecomendedCard = ({ image, title }: any) => {
  const router = useRouter();
  return (
    <Card
      onClick={() => {
        router.push("/specs");
      }}
      className="border-0 cursor-pointer"
      style={{ width: "17rem" }}
    >
      <Image
        height={211}
        width={278}
        className="rounded-2 mx-3"
        alt="productimg"
        src={image}
      />
      <Card.Body>
        <Card.Title className="mb-0">
          <p className="fs-18 fw-700 mb-0">{title}</p>
        </Card.Title>
        {/* <div className="mb-4">
          <p className="mb-0">
            Lorem Ipsum is simply dummy text of the printing{" "}
          </p>
        </div> */}
      </Card.Body>
    </Card>
  );
};
